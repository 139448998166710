import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import { Chip, Grid, Stack, Typography } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";

const UpgradePlan = () => {
  const { loginUserData, loading } = useContext(CustomProvider);
  if (loading) {
    return "";
  }
  const renewalDate = new Date(loginUserData?.membership_renewal);
  const expiryDate = new Date(loginUserData?.membership_expiry);
  return (
    <JumboCardQuick
      sx={{ textAlign: "center", color: "common.white" }}
      bgColor={["#e44a77", "#705cc7"]}
      bgGradientDir={"-135deg"}
    >
      <Typography variant={"h2"} color={'white'}>Current Rank</Typography>
      <Div
        sx={{ display: "flex", minWidth: 0, justifyContent: "center", mb: 0 }}
      >
        <img
          src={getAssetPath(`${ASSET_IMAGES}/pet-insurance.png`, "102x102")}
          alt={"Pet Insurance"}
          width={102}
        />
      </Div>

      {/* <Typography variant={"body1"} color={"common.white"} sx={{ mb: "2px" }}>
        Joining Date:{" "}
        {loginUserData?.membership === "active"
          ? renewalDate.toLocaleDateString("en-GB")
          : "N/A"}
      </Typography>

      <Typography variant={"body1"} color={"common.white"} sx={{ mb: "2px" }}>
        Renewal Date:{" "}
        {loginUserData?.membership !== "pending"
          ? expiryDate.toLocaleDateString("en-GB")
          : "N/A"}
      </Typography> */}
<br />
<br />

      <Chip
        sx={{fontSize:25}}
        label={loginUserData?.currentrankname === "Starter" ? 'N/A' : loginUserData?.currentrankname}
        color={'success'}
      />
    </JumboCardQuick>
  );
};

export default UpgradePlan;
