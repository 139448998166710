import React from "react";

import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import GroupsIcon from '@mui/icons-material/Groups';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import {ShoppingCartCheckoutRounded, Wallet, WalletOutlined } from "@mui/icons-material";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ApiIcon from '@mui/icons-material/Api';
import InventoryIcon from '@mui/icons-material/Inventory';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
const menus = [
    {
        label: 'sidebar.menu.home',
        type: "section",
        children: [
            {
                uri: "/dashboard",
                label: 'sidebar.menuItem.dashboard',
                type: "nav-item",
                icon: <GraphicEqIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/deposit",
                label: 'Deposit',
                type: "nav-item",
                icon: <AccountBalanceWalletIcon sx={{fontSize: 20}}/>
            },
            // {
            //     uri: "/signup",
            //     label: 'Add New User',
            //     type: "nav-item",
            //     icon: <AccountCircleIcon sx={{fontSize: 20}}/>
            // },
            {
                label: 'Stockist Panel',
                type: "collapsible",
                icon: <InventoryIcon sx={{fontSize: 20}}/>,
                children: [
                    {
                        label: "Dashboard",
                        type: "collapsible",
                        icon: <GraphicEqIcon sx={{fontSize: 20}}/>,

                        children: [

                            {
                                uri: "/stockist-dashboard",
                                label: "Stockist Dashboard",
                                type: "nav-item",
                            }
                                                
                        ]
                    },
                    {
                        label: "Packages Orders",
                        type: "collapsible",
                        icon: <WidgetsIcon sx={{fontSize: 20}}/>,

                        children: [

                            {
                                uri: "/stockist-packages-requests",
                                label: "Pending Packages",
                                type: "nav-item",
                            },
                            {
                                uri: "/stockist-packages-approved",
                                label: "Approved Packages",
                                type: "nav-item"
                            },
                            {
                                uri: "/stockist-packages-delivered",
                                label: "Delivered Packages",
                                type: "nav-item"
                            },
                                                
                        ]
                    },
                    {
                        label: "Products Orders",
                        type: "collapsible",
                        icon: <ShoppingBagIcon sx={{fontSize: 20}}/>,

                        children: [

                            {
                                uri: "/stockist-new-product-orders",
                                label: "New Orders",
                                type: "nav-item",
                            },
                            {
                                uri: "/stockist-products-approved-order",
                                label: "Approved Orders",
                                type: "nav-item"
                            },
                            {
                                uri: "/stockist-products-delivered-order",
                                label: "Delivered Orders",
                                type: "nav-item"
                            },
                                                
                        ]
                    },
                                        
                ]
            },{
                label: 'Packages',
                type: "collapsible",
                icon: <ApiIcon sx={{fontSize: 20}}/>,
                children: [

                    {
                        uri: "/packages",
                        label: "Buy / Upgrade",
                        type: "nav-item"
                    },
                    {
                        uri: "/package-history",
                        label: "History",
                        type: "nav-item"
                    },
                                        
                ]
            },

            {
                label: 'Online Store',
                type: "collapsible",
                icon: <ShoppingCartCheckoutRounded sx={{fontSize: 20}}/>,
                children: [

                    {
                        uri: "/ecommerce",
                        label: "Shop",
                        type: "nav-item"
                    },
                    {
                        uri: "/orderhistory",
                        label: "Order History",
                        type: "nav-item"
                    },
                                        
                ]
            },
              
            {
                uri: "/wallet-transfer",
                label: 'Wallet Transfer',
                type: "nav-item",
                icon: <WalletOutlined sx={{fontSize: 20}}/>
            },
              
            {
                uri: "/referrals",
                label: 'sidebar.menuItem.referrals',
                type: "nav-item",
                icon: <GroupsIcon sx={{fontSize: 20}}/>
            },
             {
                uri: "/payout",
                label: 'sidebar.menuItem.rpayout',
                type: "nav-item",
                icon: <AccountBalanceIcon sx={{fontSize: 20}}/>
            },
            {
                label: 'sidebar.menuItem.reports',
                type: "collapsible",
                icon: <GraphicEqIcon sx={{fontSize: 20}}/>,
                children: [

                    {
                        uri: "/deposit-report",
                        label: "Deposit Report",
                        type: "nav-item"
                    },

                    {
                        uri: "/payout-summary",
                        label: "Payout Summary",
                        type: "nav-item"
                    },

                    {
                        uri: "/wallet-transfer-report",
                        label: "Wallet Transfer",
                        type: "nav-item"
                    },
                    {
                        uri: "/unilevel-summary",
                        label: "sidebar.menuItem.unilevelsummary",
                        type: "nav-item"
                    },
                    {
                        uri: "/referral-bonus",
                        label: "sidebar.menuItem.referralbonus",
                        type: "nav-item"
                    },
                    {
                        uri: "/pairing-bonus",
                        label: "Pairing Bonus",
                        type: "nav-item"
                    },

                    {
                        uri: "/pool-bonus",
                        label: "Pool Bonus",
                        type: "nav-item"
                    },


                                        
                ]
            },
              
            {
                uri: "/binary-tree",
                label: 'sidebar.menuItem.tree',
                type: "nav-item",
                icon: <ReduceCapacityIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/profile",
                label: 'sidebar.menuItem.profile',
                type: "nav-item",
                icon: <AssignmentIndIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/news",
                label: 'sidebar.menuItem.news',
                type: "nav-item",
                icon: <NewspaperIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/logout",
                label: 'sidebar.menuItem.logout',
                type: "nav-item",
                icon: <LogoutIcon sx={{fontSize: 20}}/>
            },

            
        ]
    },
  
];

export default menus;
