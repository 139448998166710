import React, {useContext, useEffect } from 'react';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Box from "@mui/material/Box";
import {Alert, AlertTitle, Autocomplete, Button, Grid, TextField, Typography} from "@mui/material";
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import Div from '@jumbo/shared/Div/Div';
import { deployuser, postRequest, submitManualPayment } from 'backendServices/ApiCalls';
import PayPalButton from '../components/mui/Payments/Paypal';
import { CSSTransition } from 'react-transition-group';
import { Form, Formik} from "formik";
import * as yup from "yup";
import './CollapsibleBox.css'; // Import CSS for transition animation
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const validationSchema = yup.object({
  package: yup.string()
    .required('User Name is required'),
    amount: yup.string()
    .required('Amount is required'),
    stockist: yup.object()
    .required('Stockist is required'),
});





const Investment = () => {

  const [alertData, setalertData] = React.useState({
    show:false,
    message:"",
    variant:""
})
const [isOpen, setIsOpen] = React.useState(false);
const [stockistList, setStockistList] = React.useState([]);
const [citiesList, setCitiesList] = React.useState([]);
const [selectedCity, setSelectedCity] = React.useState(null);
const [hideForm, setHideForm] = React.useState(false);
const [diffAmount, setDiffAmount] = React.useState(0);
const [successMessage, setSuccessMessage] = React.useState('');

const {loginUserData,setloginUserData} = useContext(CustomProvider);
let userData = loginUserData
const navigate = useNavigate();

  const { state } = useLocation();
  const pdata = state?.data || {};
  if(state === null)
  {
    navigate('/packages')
  }

  const handleButtonCollapse = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit=(data,setSubmitting,resetForm)=>{
    let params = {
      stockistid:data.stockist.stockistid,
      packageid:pdata.id,
      amount:pdata.amount - diffAmount,
      buyingtype:loginUserData?.pkgid > 0 ? 'upgrade' : 'new'
    }
  
    postRequest('/submitpackagerequest',params, async (response) => {
      if(response?.data?.status === "success")
      {
        await setSuccessMessage(response?.data?.message)
        await setSubmitting(false)
        await setloginUserData((prevState) => ({
            ...prevState,
            deposit_balance: loginUserData?.deposit_balance - (pdata.amount - diffAmount) ,
          }));
        await setHideForm(true)
          await resetForm();
      }
      else{
          setalertData({
              show:true,
              message:response?.data?.message,
              variant:response?.data?.status
          })
          setSubmitting(false)
  
      }
  }, (error) => {

    setalertData({
      show:true,
      message:error.message,
      variant:'error'
  })

  })
  
  }

const fetchCitiesList = () => {
  if(loginUserData.state !== undefined)
  {

    postRequest('/getcities',{stateid:loginUserData.state}, async (response) => {
      await setCitiesList(response?.data?.data);
      const city = await response?.data?.data.find((city) => {
        return city.id == loginUserData.city;
      });
      await setSelectedCity(city);
    });
  }
};

const getStockistList = (cityid) => {
  // Handle the successful payment
  postRequest('/getstockistlist',{cityid},(response) => {
      setStockistList(response?.data?.data)
      }, (error) => {
         console.error(error); 

      })
};
useEffect(async () => {
  await fetchCitiesList()
  if(loginUserData?.pkgid > 0)
  {
    let params = {
      pkgid :loginUserData?.pkgid
    }
    postRequest('/getuserpackagedetails',params,(response) => {
      setDiffAmount(response?.data?.data?.amount)
      }, (error) => {
         console.error(error); 

      })
  }
}, [])


useEffect(async () => {
  await getStockistList(loginUserData?.city)
}, [selectedCity])

const initialstates = {
  package: pdata?.title || '',
  amount: `N${((pdata?.amount - diffAmount )*loginUserData?.nprice).toLocaleString()}` || '',
  accountbalance: `N${loginUserData?.accountbalance}` || '',
  city : selectedCity || null,
  stockist: null
}

    return (
      <Grid container spacing={2}  alignItems="center" justifyContent="center" >
         {
            alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
          }
     
      <Grid item sm={8} >
        {
          !hideForm ? (
            <JumboDemoCard  title={"Submit Request"}
            wrapperSx={{backgroundColor: 'background.paper', pt: 0}}>


 <Box 

     sx={{
         display: 'flex',
         flexDirection: 'column',                  
     }}
     alignItems="center"
 >


<Div sx={{mt: 2,}}>
<Typography variant="h3" gutterBottom component="div">
Select your nearest stockist to proceed
</Typography>
</Div>
{/* <PayPalButton sx={{width:'100%'}} amount={10.0} onSuccess={handlePaymentSuccess} />

<Button color='primary' variant='contained' sx={{mt:1, width:'80%'}} onClick={handleButtonCollapse}>
     Pay Using Wise
</Button>  */}

<CSSTransition in={true} classNames="collapse" timeout={300} unmountOnExit>
<Box sx={{ mt: 2, p: 2, border: '1px solid #ccc', borderRadius: '4px' }}>
<Formik
     validateOnChange={false}
     enableReinitialize = 'true'
     initialValues={initialstates}
     validationSchema={validationSchema}
     onSubmit={(data, {setSubmitting,resetForm}) => {
                 setSubmitting(true);
                 handleSubmit(data, setSubmitting,resetForm);
             }}
 >
{({isSubmitting, values, setFieldValue, touched, errors}) => (
 <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>

 <Grid container spacing={2}>
                 
     <Grid item xs={12} >
       <JumboTextField
         fullWidth
         name="package"
         label="Package"
         type="text"
         inputProps={{readOnly: true}}

       />
     </Grid>


     <Grid item xs={12}>
       <JumboTextField
         fullWidth
         name="amount"
         label="Package Amount"
         type="text"
         readonly
       />
     </Grid>


     <Grid item xs={12}>
       <JumboTextField
         fullWidth
         name="accountbalance"
         label="Account Balance"
         type="text"
         readonly
       />
     </Grid>

     <Grid item xs={12}>
       {
         diffAmount > 0 ? (
           <Alert severity='info'>
             {
               `you will pay the difference amount from your current package ==> ${pdata?.amount*loginUserData?.nprice} - ${diffAmount*loginUserData?.nprice} = N${(pdata?.amount-diffAmount)*loginUserData?.nprice}`
             }
           
         </Alert>
         )
         :
         null
       }

     </Grid>

     <Grid item sm={12}>
             <Autocomplete
           sx={{ width: "100%" }}
           value={values.city}
           onChange={async (event, newValue) => {
             await setFieldValue('city',newValue);
             await getStockistList(newValue?.id)
           }}
           id="vehmake-select"
           options={citiesList}
           autoHighlight
           getOptionLabel={(option) => option.name} // Display the title in the dropdown
           getOptionSelected={(option, value) =>
             option.id === value.id
           } // Match by code
           renderInput={(params) => (
             <TextField
               {...params}
               name="city"
               label="Select city"
               variant="outlined"
               error={touched.city && errors.city}
               helperText={touched.city && errors.city}
             />
           )}
         />
             </Grid>

     <Grid item xs={12}>
     <Autocomplete
           sx={{ width: "100%" }}
           value={values.stockist}
           onChange={(event, newValue) => {
             setFieldValue('stockist',newValue);

           }}
           id="vehmake-select"
           options={stockistList.length > 0 ? stockistList : [{ storename: 'No stockist found', address: 'No' }]}                      autoHighlight
           getOptionLabel={(option) => `${option?.storename} (${option?.address})`} // Display the title in the dropdown
           getOptionSelected={(option, value) =>
             option.stockistid === value.stockistid
           } // Match by code
           renderInput={(params) => (
             <TextField
               {...params}
               name="stockist"
               label="Select nearest stockist"
               variant="outlined"
               error={touched.stockist && errors.stockist}
               helperText={touched.stockist && errors.stockist}
             />
           )}
         />
     </Grid>

     <Grid item sm={12}>
                  <LoadingButton
                         fullWidth
                         type="submit"
                         variant="contained"
                         size="large"
                         sx={{mb: 3}}
                         //loading={isSubmitting}
                     >Submit</LoadingButton>
     </Grid>

   </Grid>

</Form>
)}
</Formik>

</Box>
</CSSTransition>



   
 </Box>

</JumboDemoCard>
          )
          :
          (
            <JumboDemoCard  title={"Package submitted successfully"}
            wrapperSx={{backgroundColor: 'background.paper', pt: 0}}>


 <Box 

     sx={{
         display: 'flex',
         flexDirection: 'column',                  
     }}
     alignItems="center"
 >
          <Alert severity='success'>{successMessage}</Alert>
          </Box>
</JumboDemoCard>
          )
        }

      </Grid>
      </Grid>
    );
};

export default Investment;








