import React, { useContext } from 'react';
import CardHeader from "@mui/material/CardHeader";
import {Card, CardActions, CardContent, LinearProgress, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';

const PortfolioBalance = (props) => {
    const { loginUserData} = useContext(CustomProvider);
    const date = new Date(); //Get the day of the week of particular date mentioned by the user
    var day = date.getDay();
    var day1 = date.getDate();
    day = ((day/7)*100);
    day1 = ((day1/30)*100);
    const dayp = day+'%';
    const day1p = day1+'%';
    return (
        <Card>
            <CardHeader title={'Pvs Summary'}/>
            <CardContent sx={{pt: 2}}>
                <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                        <Typography variant={"h5"} color={"text.secondary"} mb={2}>Available Pvs</Typography>
                        <Typography variant={"h6"} color={"text.primary"}>Left Leg
                            <Typography sx={{borderLeft: 1, ml: 1, pl: 1}} component={"span"}
                                        color={"text.secondary"}>{loginUserData?.left_points}</Typography>
                        </Typography>
                        <LinearProgress variant={"determinate"} color={"success"} value={day}
                                        sx={{
                                            width: {dayp},
                                            borderRadius: 4,
                                            height: 5,
                                            mb: 2,
                                            backgroundColor: '#E9EEEF'
                                        }}
                        />
                        <Typography variant={"h6"} color={"text.primary"}>Right Leg 
                            <Typography sx={{borderLeft: 1, ml: 1, pl: 1}} component={"span"}
                                        color={"text.secondary"}>{loginUserData?.right_points}</Typography>
                        </Typography>
                        <LinearProgress variant={"determinate"} color={"warning"} value={day1}
                                        sx={{
                                            width:  {day1p},
                                            borderRadius: 4,
                                            height: 5,
                                            backgroundColor: '#E9EEEF'
                                        }}
                        />
                    </Grid>
                    
                    <Grid item sm={6} xs={12}>
                        <Typography variant={"h5"} color={"text.secondary"} mb={2}>All Time Pvs</Typography>
                        <Typography variant={"h6"} color={"text.primary"}>Left Leg
                            <Typography sx={{borderLeft: 1, ml: 1, pl: 1}} component={"span"}
                                        color={"text.secondary"}>{loginUserData?.total_left_points}</Typography>
                        </Typography>
                        <LinearProgress variant={"determinate"} color={"success"} value={day}
                                        sx={{
                                            width: {dayp},
                                            borderRadius: 4,
                                            height: 5,
                                            mb: 2,
                                            backgroundColor: '#E9EEEF'
                                        }}
                        />
                        <Typography variant={"h6"} color={"text.primary"}>Right Leg
                            <Typography sx={{borderLeft: 1, ml: 1, pl: 1}} component={"span"}
                                        color={"text.secondary"}>{loginUserData?.total_right_points}</Typography>
                        </Typography>
                        <LinearProgress variant={"determinate"} color={"warning"} value={day1}
                                        sx={{
                                            width:  {day1p},
                                            borderRadius: 4,
                                            height: 5,
                                            backgroundColor: '#E9EEEF'
                                        }}
                        />
                    </Grid>


                </Grid>
            </CardContent>
            <CardActions sx={{pt: .5, pb: 2}}>
                        <Typography variant={"h6"} color={"text.primary"}>Total Converted
                            <Typography sx={{borderLeft: 1, ml: 1, pl: 1}} component={"span"}
                                        color={"text.secondary"}>{loginUserData?.converted_points}</Typography>
                        </Typography>
                        <LinearProgress variant={"determinate"} color={"success"} value={day}
                                        sx={{
                                            width: {dayp},
                                            borderRadius: 4,
                                            height: 5,
                                            mb: 2,
                                            backgroundColor: '#E9EEEF'
                                        }}
                        />
            </CardActions>
        </Card>
    );
};

export default PortfolioBalance;
