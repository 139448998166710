import React, { useContext } from 'react'
import { Box, Button, Card, CardContent, Fab, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import { useNavigate } from 'react-router-dom';
const PackageBox = ({ pdata }) => {

    const { loginUserData } = useContext(CustomProvider);
    const navigate = useNavigate()
    
    return (

        <Card sx={{ textAlign: 'center' }}>
            <CardContent sx={{
                backgroundImage: "linear-gradient(#38B8F2, #843CF6)",
                backgroundRepeat: 'repeat-x',
                py: 6,
            }}
            >
                <Typography variant={"h1"} fontWeight={"300"}
                    sx={{ fontSize: '3rem', color: 'common.white', mb: 0 }}>{pdata?.title}</Typography>
            </CardContent>
            <CardContent sx={{ position: 'relative' }}>
                <Fab aria-label="volume" color="secondary"
                    sx={{
                        position: 'absolute',
                        left: '50%',
                        top: '0',
                        height: 60,
                        width: 60,
                        transform: 'translate(-50%, -50%)',
                        color: '#fff'
                    }}
                >
                    N{(pdata?.amount * loginUserData?.nprice).toLocaleString()}
                </Fab>
                <TableContainer  sx={{mt:2, mb:2}}>
                    <Table sx={{ width: '100%' }} size="small" aria-label="a dense table">

                        <TableBody>
                        <TableRow>
                                    <TableCell align="left" component='th'>Daily Cap (Pairing Bonus)</TableCell>
                                    <TableCell align="right">{pdata?.daily_cap > 0 ? `N${(pdata?.daily_cap).toLocaleString()}` : '--'}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align="left" component='th'>PVS</TableCell>
                                    <TableCell align="right">{(pdata?.pvs)}</TableCell>
                                </TableRow>


                                <TableRow>
                                    <TableCell align="left" component='th'>UniLevel Income</TableCell>
                                    <TableCell align="right">{pdata?.open_levels > 0 ? `${pdata?.open_levels} Levels`: '--'} </TableCell>
                                </TableRow>

                                
                        </TableBody>
                    </Table>
                </TableContainer>

                <Button variant={"contained"} disabled={loginUserData?.pkgid >= pdata?.id ? true : false} onClick={()=>{navigate('/buy-now', { state: { data: pdata } })}} color={"primary"} disableElevation>{loginUserData?.pkgid >= pdata?.id ? 'Already a member' : 'Buy Now'}</Button>
            </CardContent>
        </Card>
    )
}

export default PackageBox