import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  TextField,
  IconButton,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import MarketingCampaign from "app/shared/widgets/MarketingCampaign/MarketingCampaign";
import ObjectCountRevenue from "../../../shared/metrics/ObjectCountCards/ObjectCountRevenue";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import RedeemIcon from "@mui/icons-material/Redeem";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Referralusers from "app/shared/widgets/ReferralUsers/ReferralUsers";
import { AlertTitle, Stack } from "@mui/material";
import PaymentsIcon from '@mui/icons-material/Payments';
import {
  referralusers,
  lasttransactions,
  dashBoardApi,
  postRequest,
} from "backendServices/ApiCalls";
import { useContext } from "react";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";

import Div from "@jumbo/shared/Div/Div";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  ContentCopyOutlined,
  ShoppingCartCheckoutRounded,
} from "@mui/icons-material";
// import { ShoppingBagOutlined, ShoppingBasket, ShoppingCartCheckoutRounded } from "@mui/icons-material";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import EarningExpenses from "app/shared/metrics/EarningExpenses";
import PortfolioBalance from "app/shared/metrics/PortfolioBalance";
import NewConnections from "../../../shared/widgets/NewConnections";
import RecentActivities from "../../../shared/widgets/RecentActivities";
import UpgradePlan from "../../../shared/widgets/UpgradePlan";

const Crypto = () => {
  const [lasttransactionsdata, setLastTransactionsData] = useState([]);
  const [picturelink, setPictureLink] = useState([]);
  const [referralusersdata, setReferralUsersData] = useState([]);
  const [copied, setCopied] = useState(false);
  const [referralLink, setReferralLink] = useState('select a referral leg first')
  const [selectedReferralSide, setSelectedReferralSide] = useState('')

  const [dashBoardUse, setDashBoardUse] = useState({
    levelBonus: 0,
    payout: 0,
    contractName: "",
    contractAmount: 0,
  });
  const [dashboardData, setDashboardData] = useState([])
  const [loader, setLoader] = useState(false);
  const { loginUserData, loading, setalertData } = useContext(CustomProvider);
  let userData = loginUserData;
  const promoStatus = loginUserData?.settingsdata?.find(
    (sdata) => sdata.keyname === "promo"
  );
  const promoImage = loginUserData?.settingsdata?.find(
    (sdata) => sdata.keyname === "promo_image"
  );

  const Swal = useSwalWrapper(); 
  const sweetAlerts = () => {
    Swal.fire({
      imageUrl: `https://onedollarnetwork.threearrowstech.com/uploads/promo/${promoImage.keyvalue}`, // Replace with your image URL
      imageAlt: "Promo image",
      confirmButtonText: "Close",
    });
  };

  const AllDashboardData = () => {

    postRequest('/dashboard','',(response) => {
      setDashboardData(response?.data?.data);
        if (response?.data?.status === "success") {
            console.log("response get Successfully");
        }
    }, (error) => {
        console.log(error?.response?.data);
    })
}
console.log("AllDashboardData",dashboardData)



  const ReferralUsers = () => {
    return new Promise((resolve, reject) => {
      // Assuming dashBoardApi internally uses callbacks
      referralusers(
        (response) => resolve(response),
        (error) => reject(error)
      );
    });
  };

  const TransactionData = () => {
    return new Promise((resolve, reject) => {
      // Assuming dashBoardApi internally uses callbacks
      lasttransactions(
        (response) => resolve(response),
        (error) => reject(error)
      );
    });
  };

  const fetchData = () => {
    setLoader(true);

    // Make all API calls concurrently using Promise.all
    Promise.all([ReferralUsers(), TransactionData()])
      .then(
        ([referralUsersResponse, transactionsResponse]) => {
          setReferralUsersData(referralUsersResponse?.data?.data?.entries);
          setPictureLink(referralUsersResponse?.data?.data?.picturelink);
          setLastTransactionsData(transactionsResponse?.data?.data?.entries);
          setLoader(false);
        }
      )
      .catch((error) => {
        setLoader(false);
        console.error(error?.response?.data);
      });
  };

  useEffect(() => {
    AllDashboardData()
    fetchData();
    if (promoStatus?.keyvalue === "On") {
      sweetAlerts();
    }
  }, []);

  if (loading || loader) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }
  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(referralLink);
    setalertData({
      show: true,
      message: "Referral link copied to clipboard",
      variant: "success",
    });
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={2}></Grid>
      <Grid item xs={12} md={8}>
        {/* <Alert severity="info">
          <AlertTitle>Alert</AlertTitle>
          You referral users are {userData?.totalRefBonus} and you have unlocked{" "}
          <strong>{dashBoardUse?.levelopen} level </strong> for earning, Refer
          more to get more levels earning.
        </Alert> */}
      </Grid>

      <Grid item xs={12} md={4}>
        <UpgradePlan />
      </Grid>

      <Grid item xs={12} md={8}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <ObjectCountRevenue
              value={`${(userData?.current_balance || 0).toLocaleString('en-NG', {style: 'currency',currency: 'NGN',})}`}
              title="Bonus Wallet Balance"
              color="primary.main"
              icon={<AccountBalanceWalletRoundedIcon fontSize="large" />}
              vertical={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ObjectCountRevenue
              value={`${(userData?.accountbalance || 0).toLocaleString('en-NG', {style: 'currency',currency: 'NGN',})}`}
              title="Purchasing Wallet Balance"
              color="info.main"
              icon={<AccountBalanceWalletRoundedIcon fontSize="large" />}
              vertical={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ObjectCountRevenue
              value={loginUserData?.activereferrals}
              title="Direct Active Referrals"
              color="info.main"
              icon={<GroupAddIcon fontSize="large" />}
              vertical={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ObjectCountRevenue
              value={`${(userData?.totalRefBonus || 0).toLocaleString('en-NG', {style: 'currency',currency: 'NGN',})}`}
              title="Referral Bonus"
              color="info.main"
              icon={<PaymentsIcon fontSize="large" />}
              vertical={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <ObjectCountRevenue
              value={`${(userData?.totalLevelBonus || 0).toLocaleString('en-NG', {style: 'currency',currency: 'NGN',})}`}
              title="Unilevel Bonus"
              color="info.main"
              icon={<PaymentsIcon fontSize="large" />}
              vertical={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ObjectCountRevenue
              value={`${(userData?.totalPairingBonus || 0).toLocaleString('en-NG', {style: 'currency',currency: 'NGN',})}`}
              title="Binary Bonus"
              color="success.main"
              icon={<PaymentsIcon fontSize="large" />}
              vertical={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ObjectCountRevenue
              value={`${(userData?.totalPoolBonus || 0).toLocaleString('en-NG', {style: 'currency',currency: 'NGN',})}`}
              title="Pool Bonus"
              color="secondary.main"
              icon={<PaymentsIcon fontSize="large" />}
              vertical={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}> 
        <JumboCardQuick
          title={"Referral Link"}
          subheader={""}
          wrapperSx={{ p: 1 }}
          headerSx={{ borderBottom: 1, borderBottomColor: "divider" }}
        >
          {userData?.status === "approved" ? (
            <Grid container spacing={2} >
              <Grid item sm={2} xs={12}>
              <FormControl  sx={{width:'100%', marginTop:2}}>
                    <InputLabel>Select Referral Leg</InputLabel>
                    <Select
                    fullWidth
                      label="Select Referral Side"
                      value={selectedReferralSide}
                      onChange={(e) =>
                        {
                          let referralLegCode
                          referralLegCode = e.target.value === 'L' ? 'L1232738' : 'R12231333'
                        setReferralLink(`${loginUserData?.referrallink}/${referralLegCode}`)
                        setSelectedReferralSide(e.target.value)
                        }
                      }
                      sx={{width:'100%'}}
                    >
                      <MenuItem value="L">Left</MenuItem>
                      <MenuItem value="R">Right</MenuItem>
                    </Select>
                  </FormControl>
              </Grid>
              <Grid item sm={10} xs={12}>
              <TextField
              fullWidth
              label={"Invite users with this link"}
              type="text"
              value={referralLink}
              margin="normal"
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <IconButton>
                    {copied ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <ContentCopyOutlined
                        onClick={handleCopy}
                        color="warning"
                      />
                    )}
                  </IconButton>
                ),
              }}
            />
              </Grid>
            </Grid>

          ) : (
            <Alert
              icon={<WarningAmberIcon />}
              variant="outlined"
              style={{ color: "red" }}
            >
              Please buy a membership to activate your referral link.
            </Alert>
          )}
        </JumboCardQuick>
      </Grid>
      <Grid item xs={12} lg={6}>
        <PortfolioBalance totalroi={userData?.totalEarningdbData?.total_earning} weeklyroi={userData?.totalEarningdbData?.weekly_earning} monthlyroi={userData?.totalEarningdbData?.monthly_earning} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <EarningExpenses earning={userData?.totalEarning} spending={userData?.totalSpending} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Referralusers
          picturelink={picturelink}
          referralusersdata={referralusersdata}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <MarketingCampaign lasttransactionsdata={lasttransactionsdata} />
      </Grid>
      {/* <Grid item xs={12} sm={8}>
        <NewConnections />
      </Grid>
      <Grid item xs={12} sm={4}>
        <RecentActivities userlevel={dashBoardUse?.levelopen} />
      </Grid> */}
    </Grid>
  );
};

export default Crypto;