import React, { useEffect, useState } from 'react';
import PackageBox from './components/PackageBox';
import { CircularProgress, Grid } from '@mui/material';
import { postRequest } from 'backendServices/ApiCalls';
import Div from '@jumbo/shared/Div';
import { useLocation } from 'react-router-dom';


const BuyPackage = () => {

    const [packages, setpackages] = useState([]);
    const [loading, setloading] = useState(true);
    

    const getPackagesList = () =>{

        postRequest('/getpackageslist','',(response) => {
                if (response?.data?.status === 'success') {
                    setpackages(response?.data?.data);
                    setloading(false)
                }
            },(error) => {
                console.log(error?.response?.data);
            }
        );
    }

    useEffect(() => {
        getPackagesList()
    }, [])
    
    if(loading){
        return  <Div
        sx={{
            display: 'flex',
            minWidth: 0,
            alignItems: 'center',
            alignContent: 'center',
            height: '100%',
        }}
      >
        <CircularProgress sx={{m: '-40px auto 0'}}/>
      </Div>
      }

    return (
        <Grid container spacing={2}>
            {packages.map((pdata, index) => (
                <Grid sx={{marginBottom:3}} item sm={4} key={index}>
                    <PackageBox pdata={pdata} />
                </Grid>
            ))}
        </Grid>
    );
};

export default BuyPackage;
