import React, { useEffect } from "react";
import List from "@mui/material/List";
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  Grid,
  MenuItem,
  TextField,
  InputLabel,
  Select,
  FormControl,
  CircularProgress,
} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useState } from "react";
import { countries } from "app/pages/components/mui/AutoCompletes/data";
import { useContext } from "react";
import { UserData } from "../UserProfile";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { postRequest, updateProfileData } from "backendServices/ApiCalls";
import { LoadingButton } from "@mui/lab";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import Div from "@jumbo/shared/Div";

const validationSchema = yup.object({
  firstname: yup.string("Enter first name").required("first name is required"),
  lastname: yup.string("Enter last name").required("last name is required"),
  email: yup
    .string("Enter email address")
    .email("Invalid email address")
    .required("Email is required"),
  mobile: yup.string("Enter mobile number").required("Mobile is required"),
  mailingaddress: yup
    .string("Enter mailing address")
    .required("Address is required"),
  country: yup.string("select country").required("Country is required"),
});

const About = () => {
  const [country, setCountry] = useState("");
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  let userData = loginUserData;
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [loading, setloading] = useState(true)
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const onSubmitForm = (data, setSubmitting) => {
    let params = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      mobile: data.mobile,
      address: data.mailingaddress,
      country: country,
      zipcode: data.zipcode,
      city: data.city,
      state: data.state,
    };
    updateProfileData(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setSubmitting(false);
          setloginUserData((prevState) => ({
            ...prevState,
            country: country,
            email: data.email,
            mobile: data.mobile,
            firstname: data.firstname,
            lastname: data.lastname,
          }));
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };


  const fetchCountriesList = () => {
    postRequest('/getcountries','', async (response) => {
      await setCountriesList(response?.data?.data);
      const country = response?.data?.data.find((country) => {
        return country.id == userData.country;
      });
      await setSelectedCountry(country);
    });
  };


  const fetchStatesList = () => {
    if(userData.country !== undefined)
    {
      postRequest('/getstates',{countryid:userData.country}, async (response) => {
        await setStatesList(response?.data?.data);
        const state = await response?.data?.data.find((state) => {
          return state.id == userData.state;
        });
        console.log(state)
        await setSelectedState(state);
      });
    }
  };

  const fetchCitiesList = () => {
    if(userData.state !== undefined)
    {
      console.log('uuu', userData.state)

      postRequest('/getcities',{stateid:userData.state}, async (response) => {
        await setCitiesList(response?.data?.data);
        const city = await response?.data?.data.find((city) => {
          return city.id == userData.city;
        });
        await setSelectedCity(city);
      });
    }
  };


useEffect(async () => {

  await fetchCountriesList()
  await fetchStatesList()
  await fetchCitiesList()
  await setloading(false)
}, [loginUserData])



  return (
    <JumboCardQuick title={"Update Profile"}>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}

      <List disablePadding>
        <Formik
          validateOnChange={true}
          enableReinitialize="true"
          initialValues={{
            firstname: userData.firstname || "",
            lastname: userData.lastname || "",
            email: userData.email || "",
            mailingaddress: userData.address || "",
            mobile: userData.mobile || "",
            country: selectedCountry || null,
            zipcode: userData.zipcode || "",
            city: selectedCity || null,
            state: selectedState || null,
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);
            onSubmitForm(data, setSubmitting);
          }}
        >
          {({ isSubmitting, values, setFieldValue, touched, errors }) => (
            <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
              <Grid container spacing={2}>


                <Grid item sm={6} xs={12}>
                  <JumboTextField
                    fullWidth
                    name="firstname"
                    label="First Name"
                    type="text"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <JumboTextField
                    fullWidth
                    label="Last Name"
                    name="lastname"
                    type="text"
                  />
                </Grid>

                <Grid item sm={6} sx={{ width: { xs: "100%" } }}>
                  <JumboTextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                  />
                </Grid>

                <Grid item sm={6} sx={{ width: { xs: "100%" } }}>
                  <JumboTextField
                    fullWidth
                    label="Mobile"
                    name="mobile"
                    type="tel"
                  />
                </Grid>

<Grid item sm={6}>
<Autocomplete
                      sx={{ width: "100%" }}
                      value={values.country}
                      onChange={(event, newValue) => {
                        setFieldValue('country',newValue || null);
                        postRequest('/getstates',{ countryid: newValue?.id }, (response) => {
                        if(response?.data?.status === "success")
                          {
                            setStatesList(response?.data?.data);
                          }
                        });
                      }}
                      id="vehmake-select"
                      options={countriesList}
                      autoHighlight
                      getOptionLabel={(option) => option.name} // Display the title in the dropdown
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          {option.name} ({option.iso2}) +{option.phonecode}
                        </Box>
                      )}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      } // Match by code
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="country"
                          label="Select Country"
                          variant="outlined"
                          error={touched.country && errors.country}
                          helperText={touched.country && errors.country}
                        />
                      )}
                    />
                        </Grid>
                        <Grid item sm={6}>
                        <Autocomplete
                      sx={{ width: "100%" }}
                      value={values.state}
                      onChange={(event, newValue) => {
                        setFieldValue('state',newValue);
                        postRequest('/getcities',{ stateid: newValue?.id }, (response) => {
                          if(response?.data?.status === "success")
                            {
                              setCitiesList(response?.data?.data);
                            }else{
                              setCitiesList([{id:newValue?.id, name:newValue?.name }]);
                            }
                          });
                      }}
                      id="vehmake-select"
                      options={statesList}
                      autoHighlight
                      getOptionLabel={(option) => option.name} // Display the title in the dropdown
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      } // Match by code
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="state"
                          label="Select state"
                          variant="outlined"
                          error={touched.state && errors.state}
                          helperText={touched.state && errors.state}
                        />
                      )}
                    />
                        </Grid>
                        <Grid item sm={6}>
                        <Autocomplete
                      sx={{ width: "100%" }}
                      value={values.city}
                      onChange={(event, newValue) => {
                        setFieldValue('city',newValue);

                      }}
                      id="vehmake-select"
                      options={citiesList}
                      autoHighlight
                      getOptionLabel={(option) => option.name} // Display the title in the dropdown
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      } // Match by code
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="city"
                          label="Select city"
                          variant="outlined"
                          error={touched.city && errors.city}
                          helperText={touched.city && errors.city}
                        />
                      )}
                    />
                        </Grid>

                <Grid item sm={6} sx={{ width: { xs: "100%" } }}>
                  <JumboTextField
                    fullWidth
                    label="Mailing Address"
                    name="mailingaddress"
                    type="text"
                  />
                </Grid>

                <Grid
                  item
                  sm={6}
                  sx={{ width: { xs: "100%" }, justifyContent: "end" }}
                >
                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    loading={isSubmitting}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </List>
    </JumboCardQuick>
  );
};

export default About;
